<div class="row align-items-center">
    <div class="col-lg-2 col-6 col-sm-3 col-md-3">
        <div class="single-partner-item">
            <img src="assets/img/partner/Accademia Nazionale della Formazione -CNL-.png" alt="image">
        </div>
    </div>
    <div class="col-lg-2 col-6 col-sm-3 col-md-3">
        <div class="single-partner-item">
            <img src="assets/img/partner/British Institutes.png" alt="image">
        </div>
    </div>
    <div class="col-lg-2 col-6 col-sm-3 col-md-3">
        <div class="single-partner-item">
            <img src="assets/img/partner/Cs Uniforma.png" alt="image">
        </div>
    </div>
    <div class="col-lg-2 col-6 col-sm-3 col-md-3">
        <div class="single-partner-item">
            <img src="assets/img/partner/Eipass.png" alt="image">
        </div>
    </div>
    <div class="col-lg-2 col-6 col-sm-3 col-md-3">
        <div class="single-partner-item">
            <img src="assets/img/partner/ETS Toefl.png" alt="image">
        </div>
    </div>
    <div class="col-lg-2 col-6 col-sm-3 col-md-3">
        <div class="single-partner-item">
            <img src="assets/img/partner/Kolegji Universitar -Reald-.png" alt="image">
        </div>
    </div>
    <div class="col-lg-2 col-6 col-sm-3 col-md-3">
        <div class="single-partner-item">
        </div>
    </div>
    <div class="col-lg-2 col-6 col-sm-3 col-md-3">
        <div class="single-partner-item">
            <img src="assets/img/partner/Kolegji Universitar Wisdom.png" alt="image">
        </div>
    </div>
    <div class="col-lg-2 col-6 col-sm-3 col-md-3 d-none">
        <div class="single-partner-item">
            <img src="assets/img/partner/Orienta Campus.png" alt="image">
        </div>
    </div>
    <div class="col-lg-2 col-6 col-sm-3 col-md-3">
        <div class="single-partner-item">
            <img src="assets/img/partner/Università Telematica -Leonardo Da Vinci-.png" alt="image">
        </div>
    </div>
    <div class="col-lg-2 col-6 col-sm-3 col-md-3">
        <div class="single-partner-item">
            <img src="assets/img/partner/Universo Docente.png" alt="image">
        </div>
    </div>
    <div class="col-lg-2 col-6 col-sm-3 col-md-3">
        <div class="single-partner-item">
            <img src="assets/img/partner/newformacademy.png" alt="image">
        </div>
    </div>
</div>
