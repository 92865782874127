<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>PAR GOL</li>
            </ul>
            <h2>Programma “Garanzia di Occupabilità dei Lavoratori — GOL”</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">
    <div class="courses-details-image">
        <img src="assets/img/pargol.jpg" style="max-height:496px; width:100%; object-fit: cover;  object-position: bottom bottom;" alt="image">
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-desc">
                    <ngx-tabset>
                        <ngx-tab tabTitle="PAR GOL">
                            <div class="courses-overview">
                                <h3>PIANO NAZIONALE DI RIPRESA E RESILIENZA (PNRR)</h3>
                                <h5><b>Programma “Garanzia di Occupabilità dei Lavoratori — GOL”</b></h5>
                                <p>Piano Nazionale di Ripresa e Resilienza (PNRR), Missione 5 “Inclusione e coesione”, Componente 1 ” Politiche per il Lavoro”, Riforma 1.1 ”Politiche Attive del Lavoro e Formazione”, finanziato daII’Unione europea — Next Generation EU</p>
                                <p>La presente sezione dedicata al «PAR GOL» espone le informazioni inerenti ai Settori Economico-Professionali di interesse dell’Agenzia formativa FORMAGOL, in relazione filiere produttive cui afferiscono le Aziende con le quali FORMAGOL ha stipulato specifici accordi di collaborazione per la realizzazione di attività di formazione nell’ambito del programma, le informazioni di dettaglio delle descritte Aziende, il Catalogo Dinamico dell’Offerta Formativa di FORMAGOL, comprensivo di tutte le informazioni concernenti ciascun percorso formativo programmato, in corso di svolgimento e/o concluso nell’ambito del programma.</p>
                            </div>
                            <div class="btn-box mt-5">
                                <a routerLink="/contatti" class="default-btn"><i class="flaticon-tag"></i>Richiedi informazioni<span></span></a>
                            </div>
                        </ngx-tab>
                    </ngx-tabset>
                </div>
            </div>
            <div class="col-lg-4 col-md-12 d-none">
                <div class="courses-details-info">
                    <div class="image">
                        <img src="assets/img/courses/img1.jpg" alt="image">
                        <a href="https://www.youtube.com/watch?v=PWvPbGWVRrU" class="link-btn popup-youtube"></a>
                        <div class="content">
                            <i class="flaticon-play"></i>
                            <span>Course Preview</span>
                        </div>
                    </div>
                    <ul class="info">
                        <li class="price">
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-tag"></i> Price</span>
                                $49
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-teacher"></i> Instructor</span>
                                Sarah Taylor
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-time"></i> Duration</span>
                                7 weeks
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-distance-learning"></i> Lessons</span>
                                25
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-web"></i> Enrolled</span>
                                255 students
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-lock"></i> Access</span>
                                Lifetime
                            </div>
                        </li>
                    </ul>
                    <div class="btn-box">
                        <a routerLink="/contatti" class="default-btn"><i class="flaticon-tag"></i>Richiedi informazioni<span></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="courses-area bg-f8f9f8 pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Altri corsi che potrebbero piacerti</h2>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6" *ngFor="let propostaCorso of nPropostaCorsi">
                <div class="single-courses-box">
                    <div class="courses-image">
                        <a target="_parent" href="/dettaglio-corso/{{propostaCorso['corso']['tag']}}" class="d-block image">
                            <img *ngIf="propostaCorso['corso']['isMiur']==1" src="assets/img/miur.png" style="height: 50px; position: absolute; bottom: 20px; left: 25px;">
                            <img src="{{urlImage+propostaCorso['corso']['image']}}" alt="image">
                        </a>
                    </div>
                    <div class="courses-content">
                        <h3><a target="_parent" href="/dettaglio-corso/{{propostaCorso['corso']['tag']}}">{{propostaCorso['corso']['titolo']}}</a></h3>
                        <p style="overflow: hidden;
                                  text-overflow: ellipsis;
                                  display: -webkit-box;
                                  -webkit-line-clamp: 3;
                                  -webkit-box-orient: vertical">{{propostaCorso['corso']['descrizione']}}</p>
                        <ul *ngIf="propostaCorso['corso']['durata'].durata_numero>0" class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li class="fw-bold"><i class='flaticon-time-left'></i>
                                {{propostaCorso['corso']['durata'].durata_numero}}{{propostaCorso['corso']['durata'].durata_label}}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

