import { AfterViewInit, Component, OnInit, ElementRef } from '@angular/core';
import { CrudService } from '../../services/crud.service';
import { Router, ActivatedRoute} from '@angular/router';
import { SchedaCorso } from '../../interface/scheda-corso';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

import { ViewChild } from '@angular/core';
import { TabComponent, TabsetComponent } from 'ngx-tabset';
import { ReturnStatement } from '@angular/compiler';

import { JitsiComponent } from 'src/app/jitsi/jitsi.component';
import { Meta, MetaDefinition } from '@angular/platform-browser';
import { Accordion } from 'ngx-accordion';
import { element } from 'protractor';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class DetailComponent implements OnInit, AfterViewInit {
    @ViewChild('staticTabs') staticTabs: TabsetComponent;
    @ViewChild('tabs2') tabs2: TabComponent;
    @ViewChild('tabs3') tabs3: TabComponent;
    @ViewChild('tabs4') tabs4: TabComponent;
    @ViewChild('tabs5') tabs5: TabComponent;
    @ViewChild("myVideo", { static: false }) myVideo: ElementRef;
    @ViewChild('pageAccordion') pageAccordion: Accordion;

    //PAYPAL
    cartTotalPrice: number = 1;
    showPaypal: boolean = false;
    isTransactionComplete: boolean = false;

    public logoPartner: string="assets/img/logo_new1.png";

    public idCorsoGlobal: string;
    public isMiur: boolean=false;

    public costoCorso: string;
    public linkEsterno: string="";
    public costoCorsoScontato: string="";
    public productID: any;
    public detailAcquisto: any;
    public detailUser: any=[];
    public schedaCorsista: any;
    public moduliUC: string[];
    public moduloUC_TestValutazione: string[];

    public moduloUC_TestValutazioneSelected: any;
    public showTestValutazione: boolean=false;

    public materialeDidattico: string[];
    public nModuliUC: number;
    public ModuloUC_frequenza: number;

    public timeCheck: number;
    public timeCheckBackup: number;

    public tempoTrascorso: number = 0;
    public tempoRestanteLimite: number = 0;
    public tempoRestanteSessione: number = 0;
    public labelTempoRestanteLimite: string;
    public labelTempoRestanteSessione: string;

    public tempoRestanteLimiteTEST: number = 0;
    public labelTempoRestanteLimiteTEST: string;

    public arraySaveDatiUC_Tempo: Array<any>=[];
    public timerHandle: any;
    public timerHandleCheck: any;
    public timeStartVideo: any;

    public nPropostaCorsi: any=[];
    public nParagrafi: number = 0;
    public elencoDataCorso: any=[];
    public detailQuiz: any=[];

    public isEnabledUser: boolean=false;
    public isInLive: number=0;
    public protocollo: any;
    public mansione: any;
    public reportForData: number=0;
    public limita_moduloUC: number=0;
    public limiteGiorno: number=0;
    public limiteGiornoAula: number=0;
    public lockModuliUC: number=0;
    public isInLiveLinkAula: string="";
    public arrayLive: any=[];

    public detallCorso: SchedaCorso = {} as SchedaCorso;
    public urlImage = environment.urlImageCorso;
    public urlImageHD = environment.urlImageCorsoHD;
    public urlVideoCorso = environment.urlImageVideoCorso;
    public videoPreview = environment.urlImageVideoCorso;
    public materialeDidatticoPath = environment.urlMaterialeDidattico;
    public urlStampa = environment.urlStampa;

    public canShowElencoDate = environment.canShowElencoDate;

    public numeroTelefono = environment.numero_telefono;
    public canShowPagamentoRate = environment.canShowPagamentoRate;
    public textShowPagamentoRate = environment.textShowPagamentoRate;

    public isLimiteRaggiunto: boolean=false;

    public briciolaTag: string;
    public cntClick: number = 1;

    public showMeet: boolean=false;
    public showMeetPreload: boolean=false;

    public arrayConnessioniVideo: any=[];

    public isFAD_Always: boolean=false;

    constructor(
        private crudService: CrudService,
        private router: Router,
        private actRoute: ActivatedRoute,
        private metaService:Meta) { }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.showEsame();
        }, 2000);
    }

   ngOnInit() {
    this.detallCorso['image']="";

    //mi recupero il dettaglio dell'user dopo il LOGIN
    this.detailUser=JSON.parse(localStorage.getItem('user_id'));

    if(!(this.detailUser)) {
        this.detailUser={'canDownloadAttestato': '0'};
    }

    //mi recupero l'ID del CORSO per ricavare la scheda
    this.productID = this.actRoute.snapshot.params['id'];

    if(this.detailUser) {
        this.crudService.api_getDetailCorsista(this.detailUser['id_corsista']).subscribe(productData => {
            //console.log(productData);

            this.detailUser['assistenza']=productData['assistenza'];
            this.detailUser['canDownloadAttestato']=productData['canDownloadAttestato'];
            this.schedaCorsista=productData;
            let idPartner=productData['id_partner'];

            this.crudService.api_get_DetailPartner(idPartner).subscribe(productData => {
                if(idPartner!=1) {
                    if(productData['image_logo'])
                        this.logoPartner=environment.urlImagePartners+productData['image_logo'];
                }

                this.urlVideoCorso+=this.detailUser['id_partner']+"/";
                this.videoPreview+=this.detailUser['id_partner']+"/";
                let idUser=this.detailUser['id_user'];
                this.mansione=this.detailUser['mansione'];
                let idCorsista=this.detailUser['id_corsista'];
                this.checkUserIsEnabled(this.productID, idCorsista);
            },(err)=>{
                //console.log(err);
                }, ()=>{
            }
            );

        },(err)=>{
            //console.log(err);
            }, ()=>{
        });
    }
    else {
        this.urlVideoCorso+="1/";
        this.videoPreview+="1/";
        this.loadDetailsCorso(this.productID, '1');
    }

    //this.loadDetailsCorso(this.productID);

     //visualizzo i dettaglio del CORSO. ritardo di 250 perchè prima mi serve capire se il corsista è abilitato
     /*
     setTimeout(() => {
     }, 500);
     */
  }

  showEsame() {
    /*
    if(this.tempoRestanteLimiteTEST>0) {
        this.staticTabs.tabs.first.active=false;
        this.tabs2.active=false;
        this.tabs3.active=false;
        this.tabs5.active=true;
    }
    */
  }

  playPause() {
    var myVideo: any = document.getElementById("myVideo");
    if (myVideo.paused) myVideo.play();
    else myVideo.pause();
  }

  initiSocialMeta() {
    this.metaService.addTag( { name:'description',content:this.detallCorso.descrizione});
  }

  checkout(){
    if(this.detailUser) {
        this.showPaypal = true;
    }
    else {
        this.confermaNewAccount();
    }
  }

  confermaNewAccount() {
    Swal.fire({
        icon: 'info',
        title: 'Non sei registrato',
        text: 'prima di procedere bisogna registrare i propri dati, vuoi procedere con la registrazione?',
        showCancelButton: true,
        confirmButtonColor: '#6993FF',
        cancelButtonColor: '#dadada',
        confirmButtonText: 'Si, procedo!',
        cancelButtonText: 'Annulla',
        width: '400px',
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
            location.href="/corsisti/nuovo-account";
        }
      })
  }

  annullaAcquisto() {
    this.showPaypal = false;
  }

  paymentApprove(event){
    //console.log(event);
    if(event.status == 'COMPLETED'){
        let idTransiction=event.id;
        let metodo="paypal";
        let status=event.status;
        let payer_id = event.payer.payer_id;
        let payer_nome=event.payer.name.given_name;
        let payer_cognome=event.payer.name.surname;
        let payer_email=event.payer.email_address;
        let purchase_id=event.purchase_units[0].payee.merchant_id;
        let purchase_importo_valuta=event.purchase_units[0].amount.currency_code;
        let purchase_importo=event.purchase_units[0].amount.value;
        let payment_id=event.purchase_units[0].payments.captures[0].id;

        var arrayDetail={'idCorso': this.idCorsoGlobal,
                         'idCorsista': this.detailUser['id_corsista'],
                         'idPartner': this.detailUser['id_partner'],
                         'metodo': metodo,
                         'idTransiction': idTransiction,
                         'status': status,
                         'payer_id': payer_id,
                         'payer_nome': payer_nome,
                         'payer_cognome': payer_cognome,
                         'payer_email': payer_email,
                         'purchase_id': purchase_id,
                         'purchase_importo_valuta': purchase_importo_valuta,
                         'purchase_importo': purchase_importo,
                         'payment_id': payment_id};

                         //console.log(arrayDetail);
      this.isTransactionComplete = true;
      this.registraPagamento(arrayDetail);
    }
    else {
        this.acquistoError();
    }
  }

  registraPagamento(arrayDetail) {
    this.crudService.api_registraPagamento(arrayDetail).subscribe(productData => {
        //console.log(productData);
        if(productData==1) {
            this.addCorsistaInAula();
        }
    },(err)=>{
        //console.log(err);
        }, ()=>{
    }
    );
  }

  addCorsistaInAula() {
    this.crudService.api_addCorsistaInAula(this.detailUser['id_corsista'], this.detailUser['id_partner'], this.idCorsoGlobal).subscribe(productData => {
        if(productData==1) {
            this.getDetailCorsista();
            this.acquistoSuccess();
        }
    },(err)=>{
        //console.log(err);
        }, ()=>{
    }
    );
  }

  getDetailCorsista() {
    this.crudService.api_getDetailCorsista(this.detailUser['id_corsista']).subscribe(productData => {

        //invio l'email di conferma attivare al corsista
        var arrayDetail={'nome': productData['nome'],
                        'cognome': productData['cognome'],
                        'nomeCorso': this.detallCorso.titolo,
                        'cf': productData['cf'],
                        'email': productData['email'],
                        'idPartner':this.detailUser['id_partner']};

        //invio l'email al corsista
        //this.sentEmailConfermaAttivazioneToCorsista(arrayDetail);
    },(err)=>{
        //console.log(err);
        }, ()=>{
    });
}

 aggiornaTempoLimiteTEST() {
    if(this.tempoRestanteLimiteTEST<=0) {
        this.tabs5.active=true;
        this.controllaQuizTEST();

        clearInterval(this.timerHandle);
        //location.reload();
        return;
    }

    this.tempoRestanteLimiteTEST-=1;
    this.labelTempoRestanteLimiteTEST=this.secondsToHms(this.tempoRestanteLimiteTEST);
 }

  aggiornaTempoLimite() {
    if(this.tempoRestanteLimite<=0) {
        return;
    }

    this.tempoRestanteLimite-=1;
    this.labelTempoRestanteLimite=this.secondsToHms(this.tempoRestanteLimite);

    if(this.tempoRestanteLimite<=0) {
        if(this.tempoTrascorso<=0)
            location.reload();

        clearInterval(this.timerHandle);
        this.chiudiVideoLimiteRaggiunto();
    }
  }

  aggiornaTempoSessione() {
    if(this.tempoRestanteSessione<=0) {
        return;
    }

    this.tempoRestanteSessione-=1;
    this.labelTempoRestanteSessione=this.secondsToHms(this.tempoRestanteSessione);

    if(this.tempoRestanteSessione<=0) {
        if(this.tempoTrascorso<=0)
            location.reload();

        clearInterval(this.timerHandle);
        this.chiudiVideoLimiteRaggiunto();
    }
  }

  aggiornaTempoLimiteAula() {
    this.tempoRestanteLimite-=1;
    this.labelTempoRestanteLimite=this.secondsToHms(this.tempoRestanteLimite);

    if(this.tempoRestanteLimite<=0) {
        this.limiteGiornoAula=0;
        this.isInLive=2;
        this.setTimeOutAulaForCorsisti();
        clearInterval(this.timerHandle);
    }
  }

  setTimeOutAulaForCorsisti() {
    let idCorsoSvolgimento=this.arrayLive['id_corso_svolgimento'];
    let idCorsista=this.detailUser['id_corsista'];

    this.crudService.api_setTImeoutCorsista(idCorsista, idCorsoSvolgimento).subscribe(productData => {
        this.showMeet=false;
    },(err)=>{
        //console.log(err);
        }, ()=>{
    });

    Swal.fire({
        title:'Evento Terminato',
        text: 'puoi uscire dall\'aula.',
        icon: 'success',
        showCancelButton: false,
        confirmButtonColor: '#6993FF',
        cancelButtonColor: '#dadada',
        confirmButtonText: 'Grazie',
        allowOutsideClick: false,
     }).then((result) => {
        if (result.isConfirmed) {
            location.reload();
        }
    });

    setTimeout(() => {
        location.reload();
    }, 5000);
  }

  selectAula() {
    this.staticTabs.tabs.first.active=false;
    this.staticTabs.tabs.last.active=true;
    this.tabs2.active=false;
  }


  selectTabModuliUC(canAdd) {
    this.staticTabs.tabs.first.active=false;
    this.staticTabs.tabs.last.active=false;
    if(this.tabs3)
        this.tabs3.active=false;
    if(this.tabs4)
        this.tabs4.active=false;
    this.tabs2.active=true;

    if(canAdd)
        this.entraInAula();
  }

  acquistoSuccess() {
    let name=this.schedaCorsista['cognome']+" "+this.schedaCorsista['nome'];
    let email="egeria.formazione@gmail.com";
    let number=this.schedaCorsista['telefono'];
    let message="Corso: <b>"+this.detallCorso.titolo+"</b> al costo di <b>€. "+this.cartTotalPrice+"</b>";
    let sceltaCorso='<b>Acquisto Online</b>';
    this.crudService.sent_Email(name, email, number, message, sceltaCorso).subscribe(responseData => {
        },(err)=>{
            }, ()=>{
        }
    );

    Swal.fire({
        title:'Pagamento Effettuato',
        text: 'effettuato con successo, puoi accedere al corso.',
        icon: 'success',
        showCancelButton: false,
        confirmButtonColor: '#6993FF',
        cancelButtonColor: '#dadada',
        confirmButtonText: 'Iniza il corso',
        allowOutsideClick: false,
     }).then((result) => {
        if (result.isConfirmed) {
            location.reload();
        }
    });
  }


  acquistoError() {
    Swal.fire({
        title:'Pagamento non riuscito!',
        text: 'non siamo riusciti a completare il pagamento.',
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#6993FF',
        cancelButtonColor: '#dadada',
        confirmButtonText: 'Riprova',
        allowOutsideClick: false,
     }).then((result) => {
        if (result.isConfirmed) {
            location.reload();
        }
    });
  }

  noFADAttiva() {
    Swal.fire({
        title:'FAD non attiva!',
        text: 'non sei abilitato alla visualizzazione del corso in questo giorno o fascia d\'orario, consulta l\'elenco date',
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#6993FF',
        cancelButtonColor: '#dadada',
        confirmButtonText: 'Grazie',
        allowOutsideClick: false,
     }).then((result) => {
        if (result.isConfirmed) {

        }
    });
  }

  noAulaAttiva() {
    Swal.fire({
        title:'Aula non attiva!',
        text: 'non è stato ancora creato il link per connetterti in aula virtuale, riprova più tardi.',
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#6993FF',
        cancelButtonColor: '#dadada',
        confirmButtonText: 'Grazie',
        allowOutsideClick: false,
     }).then((result) => {
        if (result.isConfirmed) {

        }
    });
  }

  entraInAula() {
     if(this.arrayLive.length<=0) {
         return;
     }

      let arrayDetails={'idCorsista':this.arrayLive['id_corsista'],
                        'idCorsoSvolgimento': this.arrayLive['id_corso_svolgimento'],
                        'idCorsoHeader':this.arrayLive['id_corso_header'],
                        'durata': this.arrayLive['durata'],
                        'nome_aula':this.arrayLive['nome_aula'],
                        'link_aula':this.arrayLive['link_aula'],
                        'dataInizio': this.arrayLive['data_ora'],
                        'dataFine': this.arrayLive['data_ora_fine_full']};
      this.crudService.set_entraInAulaCorsista(arrayDetails).subscribe(responseData => {
         // this.showMeet=true;
          if(responseData==1) {}
      });
    }

   entraInAulaMeet() {
        if(this.arrayLive.length<=0) {
            return;
        }

        this.showMeet=true;
        this.showMeetPreload=true;

        setTimeout(() => {
            var tk=document.getElementById('jitsi-iframe') as HTMLInputElement;
            tk.style.display="block";

            this.showMeetPreload=false;
        }, 2000);



        /*
         let arrayDetails={'idCorsista':this.arrayLive['id_corsista'],
                           'idCorsoSvolgimento': this.arrayLive['id_corso_svolgimento'],
                           'idCorsoHeader':this.arrayLive['id_corso_header'],
                           'durata': this.arrayLive['durata'],
                           'nome_aula':this.arrayLive['nome_aula'],
                           'link_aula':this.arrayLive['link_aula'],
                           'dataInizio': this.arrayLive['data_ora'],
                           'dataFine': this.arrayLive['data_ora_fine_full']};
         this.crudService.set_entraInAulaCorsista(arrayDetails).subscribe(responseData => {
             this.showMeet=true;

             var tk=document.getElementById('jitsi-iframe') as HTMLInputElement;
             tk.style.display="block";

             if(responseData==1) {}
         });
         */
    }

  openLinkografia(url) {
    if(!url){
        return null;
      } else{
        if (!url.match(/^https?:\/\//i)) {
            url = 'http://' + url;
        }
        window.open(url, '_blank');
      }
  }

  loadDetailsCorso(idCorso, isOnlyDAD){
    var idCorsista='';
    if(this.detailUser)
        idCorsista=this.detailUser['id_corsista'];

    this.crudService.api_get_DetailCorso(idCorso, idCorsista, this.reportForData).subscribe(product => {
        //console.log(product);
        let idCorso=product['id_corso'];
        this.isMiur=product['isMiur'];
        this.idCorsoGlobal=product['id_corso'];

        let durataLabel=product['durata']['durata_label']=='h' ? 'ore' : product['durata']['durata_label'];
        this.detallCorso={
            durataNumero: product['durata']['durata_numero'],
            durata: product['durata']['durata_numero']+' ' +durataLabel,
            titolo: product['titolo'],
            descrizione: product['descrizione'],
            categoria: product['categoria'],
            coin: product['numero_crediti_corsista_value'],
            costo: parseFloat(product['costo']),
            image: product['image'],
            programma: product['programma'],
            moduliUC: product['moduloUC'],
            tokenMeet: product['tokenMeet'],
            haveBuy: product['haveBuy']
        };
        //console.log(this.detallCorso);

        if(this.arrayLive.hasOwnProperty('isToday')==false) {
            //console.log(this.detallCorso);
            //console.log(this.arrayLive);

            if(this.detallCorso['haveBuy']) {
                this.lockModuliUC=0;
                this.isInLive=1;
            }
        }

        this.costoCorso=parseFloat(product['costo']).toFixed(2).toString().replace(".",",");
        this.linkEsterno=product['link_esterno'];
        this.cartTotalPrice=parseFloat(product['costo']);

        if(parseFloat(product['costo_scontato'])>0) {
            this.costoCorsoScontato=parseFloat(product['costo_scontato']).toFixed(2).toString().replace(".",",");
            this.cartTotalPrice=parseFloat(product['costo_scontato']);
        }

        this.timeCheck=product['time_check'];

        if(this.isInLive==1 && (this.arrayLive['time_check']==1)) {
            if(this.timeCheck>0) {
                this.timeCheck=this.timeCheck*60;
                this.timeCheckBackup=this.timeCheck;
                this.timerHandleCheck=setInterval(() => {
                    this.countTimeCheck();
                }, 1000);
            }
        }
        /*
        fetch(environment.urlImageCorso+"scheda/"+product['id_corso']+'.txt')
            .then(response => response.text())
            .then(data => {
  	            // Do something with your data
  	            console.log(data);
            }
        );
        */
        document.getElementById("programmaHTML").innerHTML=product['programma'];
        this.ModuloUC_frequenza=product['ModuloUC_frequenza'];
        this.nModuliUC=product['moduloUC'].length;
        this.nPropostaCorsi=product['propostaCorsi'];

        if(product['moduloUC'].length)
            this.videoPreview+=product['moduloUC'][0]['paragrafi'][0]['sotto-paragrafi'][0]['video'];

        product['moduloUC'].forEach(element => {
            this.nParagrafi+=element['paragrafi'].length;
        });
        this.moduliUC=product['moduloUC'];
        if(this.ModuloUC_frequenza<100) {
            this.ModuloUC_frequenza=100;
            this.moduliUC.forEach(elementUC => {
                elementUC['paragrafi'].forEach(elementSotto => {
                    elementSotto['sotto-paragrafi'].forEach(element => {
                        if(element['haveReadPDF']==0) {
                            this.ModuloUC_frequenza=0;
                            return false;
                        }
                    });
                });
            });
        }

        console.log(this.ModuloUC_frequenza);

        this.moduloUC_TestValutazione=product['moduloUC_TestValutazione'];
        //console.log(this.moduloUC_TestValutazione);

        if(this.moduloUC_TestValutazioneSelected) {
            this.showTestValutazione=true;
        }

        this.materialeDidattico=product['materiale_didattico'];

        //this.checkUserIsEnabled(idCorso, idCorsista);

        this.detailAcquisto=product['haveBuyDetail'];
        if(this.detailAcquisto.length<=0)
            this.detailAcquisto="";

        let idCorsoSvolgimento=(this.arrayLive['id_corso_svolgimento']);
        this.crudService.api_get_DetailQuiz(idCorso, idCorsista, idCorsoSvolgimento).subscribe(product => {
            console.log(product);
            this.detailQuiz=product;
            this.detailQuiz['id_corso']=idCorso;

            if(this.detailQuiz['terminaTEST']>1) {
                this.ModuloUC_frequenza=100;
                this.tempoRestanteLimiteTEST=this.detailQuiz['terminaTEST'];

                this.timerHandle=setInterval(() => {
                    this.aggiornaTempoLimiteTEST();
                }, 1000);
            }
        });

        //effettuo diversi controlli
        if(this.arrayLive['isLive']!=1) {
            if(this.isFAD_Always==true) {
                this.lockModuliUC=0;
                this.reportForData=1;
                this.isInLive=1;
                this.limita_moduloUC=1;
            }
        }

        //se è stata ATTIVATA la funzione FAD SEMPRE ATTIVA allora sblocco la FAD
        if(this.isFAD_Always==true) {
            this.lockModuliUC=0;
            this.isInLive=1;
        }
        //indica che il corsista ha acquistato il corso, quindi non ha eventi, il corso è LIBERO
        else if((this.arrayLive.hasOwnProperty('isToday')==false) || (this.arrayLive['isToday']==0)) {
            if(this.detallCorso['haveBuy'] && isOnlyDAD) {
                this.lockModuliUC=0;
                this.isInLive=1;
            }
        }

        //console.log(this.arrayLive);

        //inizializzo i meta tag per le gestione social
        this.initiSocialMeta();

    });
  }



  GetAllSelectRadioButtons() {
    var arrInput = document.getElementsByTagName("input"), arrRadio = [];
    var j = 0;
    for (var i = 0; i < arrInput.length; i++) {
           if (arrInput[i].type == "radio" && arrInput[i].checked) {
                //var arrayDetail={'value': arrInput[i].value, 'name':arrInput[i].name};
                  arrRadio[j] = arrInput[i];
                  j++;
           }
    }
    return arrRadio;
}

GetAllSelectRadioButtonsTEST() {
    var arrInput = document.getElementsByTagName("input"), arrRadio = [];
    var j = 0;
    for (var i = 0; i < arrInput.length; i++) {
        if (arrInput[i].type == "radio") {
            //var idRisposta="";
            //if(arrInput[i].checked)
                //idRisposta=arrInput[i].checked.;
            //var arrayDetail={'value': arrInput[i].value, 'name':arrInput[i].name};
            arrRadio[j] = arrInput[i];
            j++;
        }

    }
    return arrRadio;
}

 controllaQuizTEST() {
    var arrayQuiz=this.GetAllSelectRadioButtonsTEST();
    var arrayRisposte=new Array();

    let idQuizHeader=this.detailQuiz['id_quiz_header'];
    let idCorsista=this.detailUser['id_corsista'];
    let idCorsoSvolgimento=this.arrayLive['id_corso_svolgimento'];
    let idCorso=this.detailQuiz['id_corso'];

    //mi serve per la gestione della generazione dell'attestato
    var arrayDetail={'id_corso': idCorso, 'id_corsista': idCorsista, 'id_corso_svolgimento': idCorsoSvolgimento};

    var domandaPrecedente="";
    var risposta="";
    var initIndex=0;
    var domanda="";

    if(arrayQuiz.length>0)
        domandaPrecedente=arrayQuiz[0].name;


    //raggruppo l'array in base alle domande
    arrayQuiz = arrayQuiz.reduce(function (r, a) {
        r[a.name] = r[a.name] || [];
        r[a.name].push(a);
        return r;
    }, Object.create(null));

    for (var key in arrayQuiz) {
        var value = arrayQuiz[key];
        risposta="";
        value.forEach(element => {
            if(element.checked==true) {
                risposta=element.value;
            }
        });
        arrayRisposte.push({"id_corso":idCorso, "id_header":idQuizHeader, "id_corsista":idCorsista, "id_corso_svolgimento":idCorsoSvolgimento, "id_domanda":key, "id_risposta":risposta, 'isTestValutazione': '0'});
    };

    document.getElementById('btnTest').remove();

    this.crudService.api_verifica_quiz(arrayRisposte).subscribe(product => {
        //console.log(product);
        let corrette=product['corrette'];
        let nonCorrette=product['non_corrette'];
        let numeroDomande=product['numero_domande'];
        let sogliaMinimaPercentuale=product['soglia_minima_percentuale'];
        let sogliaMinimaDomande=product['soglia_minima_domande'];
        let sogliaCorrettePercentuale=product['soglia_corrette_percentuale'];
        if(corrette>=sogliaMinimaDomande) {
            if(this.detailUser['canDownloadAttestato']==0) {
                //test superato
                this.quizSuperato(corrette, numeroDomande);
                return;
            }

            //genero l'attestato
            this.crudService.api_GeneraAttestato(arrayDetail).subscribe(product => {
                //console.log(product);
                let nomeCorsista=product['nome_corsista'];
                let data_nascita=product['data_nascita'];
                let provincia_nascita=product['provincia_nascita'];
                let comune_nascita=product['comune_nascita'];
                let data_attestato=product['data_attestato'];
                let cf=product['cf'];
                let codiceAteco=product['codice_ateco'];
                let isPresent=product['isPresent'];
                let attestato=product['attestato'];
                let dataStart=product['dataStart'];
                if(isPresent.hasOwnProperty('id_certificato')) {
                    window.open(environment.urlAttestati+isPresent.file);

                    setTimeout(() => {
                        //test superato
                        this.quizSuperato(corrette, numeroDomande);
                    }, 250);
                }
                else {
                    if(attestato) {
                        var detail='?id_corso='+idCorso+'&id_corso_svolgimento='+idCorsoSvolgimento+'&id_corsista='+idCorsista+'&nome_corsista='+nomeCorsista+'&data_nascita='+data_nascita+'&provincia_nascita='+provincia_nascita+'&comune_nascita='+comune_nascita+'&data_attestato='+data_attestato+'&dataStart='+dataStart+'&cf='+cf+'&protocollo='+this.protocollo+'&mansione='+this.mansione+'&codiceAteco='+codiceAteco;
                        window.open(environment.urlAttestatiDaCreare+attestato+".php"+detail);

                        setTimeout(() => {
                            //test superato
                            this.quizSuperato(corrette, numeroDomande);
                        }, 250);
                    }
                    else {
                        Swal.fire({
                            title: 'Errore!',
                            text: "non è stasto creato l\'attestato .pdf, non posso procedere alla generazione dell\'attestato",
                            icon: 'error',
                            showCancelButton: false,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Risolvi',
                            cancelButtonText: 'Annulla'
                          }).then((result) => {
                            if (result.isConfirmed) {
                            }
                        })
                    }
                }
            });
        }
        else {
            //test non superato
            this.quizNonSuperato(corrette, nonCorrette);
        }

    });
 }

  controllaQuiz() {
    var arrayQuiz=this.GetAllSelectRadioButtons();
    var arrayRisposte=new Array();

    let idQuizHeader=this.detailQuiz['id_quiz_header'];
    let idCorsista=this.detailUser['id_corsista'];
    let idCorsoSvolgimento=this.arrayLive['id_corso_svolgimento'];
    let idCorso=this.detailQuiz['id_corso'];

    //mi serve per la gestione della generazione dell'attestato
    var arrayDetail={'id_corso': idCorso, 'id_corsista': idCorsista, 'id_corso_svolgimento': idCorsoSvolgimento};

    arrayQuiz.forEach((element) => {
        var domanda=element.name;
        var risposta=element.value;
        arrayRisposte.push({"id_corso":idCorso, "id_header":idQuizHeader, "id_corsista":idCorsista, "id_corso_svolgimento":idCorsoSvolgimento, "id_domanda":domanda, "id_risposta":risposta, 'isTestValutazione': '0'});
    });

     //controllo se l'utente ha risposto a tutte le domande, in caso contrario visualizzo il msg
     if(arrayRisposte.length<this.detailQuiz['body'].length) {
       this.quizNonCompleto();
        return;
    }

    document.getElementById('btnTest').remove();


    this.crudService.api_verifica_quiz(arrayRisposte).subscribe(product => {
        //console.log(product);
        let corrette=product['corrette'];
        let nonCorrette=product['non_corrette'];
        let numeroDomande=product['numero_domande'];
        let sogliaMinimaPercentuale=product['soglia_minima_percentuale'];
        let sogliaMinimaDomande=product['soglia_minima_domande'];
        let sogliaCorrettePercentuale=product['soglia_corrette_percentuale'];
        if(corrette>=sogliaMinimaDomande) {
            if(this.detailUser['canDownloadAttestato']==0) {
                //test superato
                this.quizSuperato(corrette, numeroDomande);
                return;
            }

            //genero l'attestato
            this.crudService.api_GeneraAttestato(arrayDetail).subscribe(product => {
                //console.log(product);
                let nomeCorsista=product['nome_corsista'];
                let data_nascita=product['data_nascita'];
                let provincia_nascita=product['provincia_nascita'];
                let comune_nascita=product['comune_nascita'];
                let data_attestato=product['data_attestato'];
                let cf=product['cf'];
                let codiceAteco=product['codice_ateco'];
                let isPresent=product['isPresent'];
                let attestato=product['attestato'];
                let dataStart=product['dataStart'];
                if(isPresent.hasOwnProperty('id_certificato')) {
                    window.open(environment.urlAttestati+isPresent.file);

                    setTimeout(() => {
                        //test superato
                        this.quizSuperato(corrette, numeroDomande);
                    }, 250);
                }
                else {
                    if(attestato) {
                        var detail='?id_corso='+idCorso+'&id_corso_svolgimento='+idCorsoSvolgimento+'&id_corsista='+idCorsista+'&nome_corsista='+nomeCorsista+'&data_nascita='+data_nascita+'&provincia_nascita='+provincia_nascita+'&comune_nascita='+comune_nascita+'&data_attestato='+data_attestato+'&dataStart='+dataStart+'&cf='+cf+'&protocollo='+this.protocollo+'&mansione='+this.mansione+'&codiceAteco='+codiceAteco;
                        window.open(environment.urlAttestatiDaCreare+attestato+".php"+detail);

                        setTimeout(() => {
                            //test superato
                            this.quizSuperato(corrette, numeroDomande);
                        }, 250);
                    }
                    else {
                        Swal.fire({
                            title: 'Errore!',
                            text: "non è stasto creato l\'attestato .pdf, non posso procedere alla generazione dell\'attestato",
                            icon: 'error',
                            showCancelButton: false,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Risolvi',
                            cancelButtonText: 'Annulla'
                          }).then((result) => {
                            if (result.isConfirmed) {
                            }
                        })
                    }
                }
            });
        }
        else {
            //test non superato
            this.quizNonSuperato(corrette, nonCorrette);
        }

    });
  }

  quizSuperato(risposteCorrette, numeroTotaleDomande) {
    Swal.fire({
        title:'Complimenti!',
        text: 'hai superato il test con il numero di '+risposteCorrette+' risposte corrette su un totale di '+numeroTotaleDomande+' domande',
        icon: 'success',
        showCancelButton: false,
        confirmButtonColor: '#6993FF',
        cancelButtonColor: '#dadada',
        confirmButtonText: 'Grazie',
        allowOutsideClick: false,
     }).then((result) => {
        if (result.isConfirmed) {
            location.reload();
        }
    });
  }

  quizNonSuperato(risposteCorrette, risposteNonCorrette) {
    Swal.fire({
        title:'Test Fallito!',
        text: 'non hai superato il test, hai sbagliato '+risposteNonCorrette+' risposte, hai risposto in modo esatto solo a '+risposteCorrette+' domande.',
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#6993FF',
        cancelButtonColor: '#dadada',
        confirmButtonText: 'Riprova',
        allowOutsideClick: false,
     }).then((result) => {
        if (result.isConfirmed) {
            location.reload();
        }
    });
  }

  quizNonCompleto() {
    Swal.fire({
        title:'Test Incompleto!',
        text: 'devi rispondere a tutte le domande, verifica',
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#6993FF',
        cancelButtonColor: '#dadada',
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
     }).then((result) => {
        if (result.isConfirmed) {

        }
    });
  }

  countTimer() {
    var myVideo: any = document.getElementById("myVideo");
    var precedenteTempo=this.tempoTrascorso;
    this.tempoTrascorso=parseInt(myVideo.currentTime);

    if(parseFloat(this.arrayLive['limita_moduloUC_durata'])>0) {
        if(this.limiteGiorno>0) {
            if(precedenteTempo!=this.tempoTrascorso)
                this.aggiornaTempoLimite();
        }
    }
 }

 countTimerNoLimite() {
    this.aggiornaTempoLimite();
 }

 showTimecheck() {
    let timerInterval;

    var now = new Date();
    var strDateTime = [[this.AddZero(now.getFullYear()),
        this.AddZero(now.getMonth() + 1),
        now.getDate()].join("-"),
        [this.AddZero(now.getHours()),
        this.AddZero(now.getMinutes()),
        this.AddZero(now.getSeconds())].join(":")].join(" ");

    Swal.fire({
        allowOutsideClick: false,
        title: 'Controllo presenza',
        icon: 'warning',
        html: 'clicca sul pulsante <span class="fw-bolder">"Si, sono presente!"</span> per conferma che sei presente online. Tempo residuo <b></b> secondi',
        timer: 60000,
        timerProgressBar: true,
        showCancelButton: false,
        confirmButtonText: 'Si, sono presente!',
        cancelButtonText: 'No, cancel!',
        confirmButtonColor: 'var(--mainColor)',
        didOpen: () => {
            clearInterval(this.timerHandleCheck);
            window.focus();

            //Swal.showLoading()
            const b = Swal.getHtmlContainer().querySelector('b');
            timerInterval = setInterval(() => {
              b.textContent = parseInt((Swal.getTimerLeft()/1000).toFixed(0)).toString();
            }, 100)
        },
        willClose: () => {
            this.timeCheck=this.timeCheckBackup;
            this.timerHandleCheck=setInterval(() => {
                this.countTimeCheck();
            }, 1000);
        }
    }).then((result) => {
        if (result.isConfirmed) {
            //sono presente
            this.salvaCheckTime(strDateTime, 1);
        }
        if (result.dismiss === Swal.DismissReason.timer) {
            //tempo check scaduto
            this.salvaCheckTime(strDateTime, 0);
        }
    })
 }

 salvaCheckTime(dateTimeInizio, isPresent) {
    var now = new Date();
    var strDateTime = [[this.AddZero(now.getFullYear()),
        this.AddZero(now.getMonth() + 1),
        now.getDate()].join("-"),
        [this.AddZero(now.getHours()),
        this.AddZero(now.getMinutes()),
        this.AddZero(now.getSeconds())].join(":")].join(" ");

    let idCorsoSvolgimento=(this.arrayLive['id_corso_svolgimento']);
    let idCorsista=this.detailUser['id_corsista'];
    this.crudService.salvaCheckTime(dateTimeInizio, strDateTime, isPresent, idCorsoSvolgimento, idCorsista, this.productID).subscribe(product => {
        //console.log(product);
    });
 }

 AddZero(num) {
    return (num >= 0 && num < 10) ? "0" + num : num + "";
}

 countTimeCheck() {
    if(this.timeCheck<=1) {
        clearInterval(this.timerHandleCheck);
        this.showTimecheck();
    }
    this.timeCheck-=1;
 }

 countTimerAula() {
    if(this.limiteGiornoAula>0) {
        this.aggiornaTempoLimiteAula();
    }
 }

  startTimer(idCorso, idPartner, id_modulo_body, id_modulo_body_paragrafo) {
    this.arraySaveDatiUC_Tempo['idCorso']=idCorso;
    this.arraySaveDatiUC_Tempo['idPartner']=idPartner;
    this.arraySaveDatiUC_Tempo['idModuloBody']=id_modulo_body;
    this.arraySaveDatiUC_Tempo['idModuloBodyParagrafo']=id_modulo_body_paragrafo;
    this.arraySaveDatiUC_Tempo['idCorsista']=this.detailUser['id_corsista'];

    this.timerHandle=setInterval(() => {
        this.countTimer();
    }, 1000);

    this.initVideoPlayer();
  }

  secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d / 60) % 60);
    var s = (d % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? "h " : "h ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? "m " : "m ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? "s " : "s") : "";
    return hDisplay + mDisplay + sDisplay;
}

  secondToMinute(duration) {
    // Hours, minutes and seconds
    var hrs = ~~(duration / 3600);
    var mins = ~~((duration % 3600) / 60);
    var secs = ~~duration % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    if (hrs > 0) {
        //ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
        mins=(hrs*60)+mins;
    }

    ret += "" + mins + "." + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
}

  playVideo(videoFile, titolo, idCorso, idPartner, id_modulo_body, id_modulo_body_paragrafo, minuteStart, tempoRestante) {
    //console.log((parseFloat(minuteStart)+(this.tempoTrascorso/100)));
    //minuteStart=Math.round(((parseFloat(minuteStart)+(this.tempoTrascorso/100)))).toFixed(2).toString();

    this.crudService.getTimeVideoVisualizzato(this.detailUser['id_corsista'], idCorso, idPartner, id_modulo_body, id_modulo_body_paragrafo, this.reportForData).subscribe(responseData => {
        minuteStart=responseData;

        if(minuteStart>=tempoRestante)
            minuteStart=0;


        videoFile=videoFile.trim();
        this.entraInAula();

        if(minuteStart!=0 && minuteStart!="") {
            var ar=minuteStart.split(".");
            if(ar.length) {
                var minuti=ar[0];
                var secondi=0;
                if(ar.length>1)
                    secondi=ar[1];

                //converto i minuti in secondi
                minuteStart=(minuti*60)+(secondi*1);
            }
        }

        //imposto il tempo iniziale da dove ho lasciato quando il video corso è stato chiuso
        //this.tempoTrascorso=(minuteStart*1);
        this.timeStartVideo=(minuteStart*1);

        Swal.fire({
            html: ' <img style="max-height:100px" src="'+this.logoPartner+'"><br><hr>\
                    <span style="font-weight:800; font-size:18px; color:var(--blackColor)">'+titolo+'</span>\
                   <video #myVideo height="419px" width="100%" style="margin-top:15px;" oncontextmenu="return false;" id="myVideo" autoplay playsinline controls controlsList="nodownload noplaybackrate" src="'+videoFile+'#t='+(minuteStart*1)+'"></video>\
                   <style>\
                   video::-webkit-media-controls-timeline {\
                        display: none;\
                    }</style>',
            width: '800px',
            heightAuto: false,
            showCancelButton: false,
            confirmButtonColor: '#6993FF',
            cancelButtonColor: '#dadada',
            confirmButtonText: 'Chiudi / Ritorna al corso',
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                clearInterval(this.timerHandle);
                this.chiudiVideo();
            }
        });

        setTimeout(() => {
            //avvio il timer
            this.startTimer(idCorso, idPartner, id_modulo_body, id_modulo_body_paragrafo);
        }, 250);
    });
  }

  openRoom() {
   this.showMeet=true;
  }

  initVideoPlayer() {
    var myVideo: any = document.getElementById("myVideo");

    var supposedCurrentTime = 0;
    myVideo.addEventListener('timeupdate', function() {
        if (!myVideo.seeking) {
            supposedCurrentTime = myVideo.currentTime;
        }
    });

    myVideo.addEventListener('pause', function() {
        //console.log("pause");
    });

    myVideo.addEventListener('playing', function() {
        //console.log("play");
    });

    myVideo.addEventListener('seeking', function() {
        // guard agains infinite recursion:
        // user seeks, seeking is fired, currentTime is modified, seeking is fired, current time is modified, ....
        var delta = myVideo.currentTime - supposedCurrentTime;
        // delta = Math.abs(delta); // disable seeking previous content if you want
        if (delta > 0.01) {
            myVideo.currentTime = supposedCurrentTime;
        }
        else if(delta < 0) {
            myVideo.currentTime = supposedCurrentTime;
        }
    });
  }

  dettaglioConnessioniVideo(idCorso, idCorsoSvolgimento, data, idCorsista) {
    if(!idCorso || !idCorsista || !data)
        return;

    var arrayDetail={'idCorso': idCorso, 'idCorsista': idCorsista, 'dataDay': data};

    this.crudService.api_getDetailTempoConnessioneDay(arrayDetail).subscribe(responseData => {
        var arrayData: any=[];
        this.arrayConnessioniVideo.splice(0);
        arrayData=responseData;
        arrayData.forEach(element => {
            this.arrayConnessioniVideo.push({'titoloUC': element['titoloUC'], 'paragrafo': element['paragrafo'], 'titolo': element['titolo'], 'durata': element['durataFormattata']});
        });

        this.showDetailVideoConnessioni(this.arrayConnessioniVideo, idCorsoSvolgimento);
    });
  }

  chiudiVideo() {
    this.arraySaveDatiUC_Tempo['tempoTrascorso']=this.secondToMinute(this.tempoTrascorso);
    if(this.tempoTrascorso>parseFloat(this.timeStartVideo))
        this.arraySaveDatiUC_Tempo['tempoTrascorso']=this.secondToMinute(this.tempoTrascorso-parseFloat(this.timeStartVideo));
    this.arraySaveDatiUC_Tempo['idCorsoSvolgimento']=this.elencoDataCorso[0]['id_corso_svolgimento'];
    this.arraySaveDatiUC_Tempo['isInLive']=this.reportForData;
    this.arraySaveDatiUC_Tempo['isPDF']='0';

    if(this.isFAD_Always==false) {
        this.crudService.salveTempoTrascorsoVideo(this.arraySaveDatiUC_Tempo).subscribe(responseData => {
            let totaleAggiornato=(parseFloat(this.timeStartVideo)+(parseFloat(this.secondToMinute(this.tempoTrascorso))*100)).toString();
            document.getElementById("video_"+this.arraySaveDatiUC_Tempo['idModuloBodyParagrafo']).innerHTML=responseData.toString();
            document.getElementById("video_"+this.arraySaveDatiUC_Tempo['idModuloBodyParagrafo']).classList.remove("d-none");
            this.tempoTrascorso=0;
            this.videoTempoAggiornato();
        });
    }
  }

  chiudiVideoLimiteRaggiunto() {
    this.arraySaveDatiUC_Tempo['tempoTrascorso']=this.secondToMinute(this.tempoTrascorso);
    if(this.tempoTrascorso>parseFloat(this.timeStartVideo))
        this.arraySaveDatiUC_Tempo['tempoTrascorso']=this.secondToMinute(this.tempoTrascorso-parseFloat(this.timeStartVideo));
    this.arraySaveDatiUC_Tempo['idCorsoSvolgimento']=this.elencoDataCorso[0]['id_corso_svolgimento'];
    this.arraySaveDatiUC_Tempo['isInLive']=this.reportForData;
    this.arraySaveDatiUC_Tempo['isPDF']='0';

    if(this.isFAD_Always==false) {
        this.crudService.salveTempoTrascorsoVideo(this.arraySaveDatiUC_Tempo).subscribe(responseData => {
            //console.log(responseData);
            //location.reload();
            let totaleAggiornato=(parseFloat(this.timeStartVideo)+(parseFloat(this.secondToMinute(this.tempoTrascorso))*100)).toString();
            document.getElementById("video_"+this.arraySaveDatiUC_Tempo['idModuloBodyParagrafo']).innerHTML=responseData.toString();
            document.getElementById("video_"+this.arraySaveDatiUC_Tempo['idModuloBodyParagrafo']).classList.remove("d-none");
            this.tempoTrascorso=0;
            this.limiteRaggiunto(1);
        });
    }
  }

  downloadPDF_ModuloUC(idModuloBody, idModuloBodyParagrafo) {
    this.arraySaveDatiUC_Tempo['tempoTrascorso']='';
    this.arraySaveDatiUC_Tempo['idCorso']=this.idCorsoGlobal;
    this.arraySaveDatiUC_Tempo['idCorsista']=this.detailUser['id_corsista'];
    this.arraySaveDatiUC_Tempo['idPartner']=this.detailUser['id_partner'];
    this.arraySaveDatiUC_Tempo['idModuloBody']=idModuloBody;
    this.arraySaveDatiUC_Tempo['idModuloBodyParagrafo']=idModuloBodyParagrafo;
    this.arraySaveDatiUC_Tempo['idCorsoSvolgimento']=this.elencoDataCorso[0]['id_corso_svolgimento'];
    this.arraySaveDatiUC_Tempo['isInLive']=this.reportForData;
    this.arraySaveDatiUC_Tempo['isPDF']='1';

    this.crudService.salveTempoTrascorsoVideo(this.arraySaveDatiUC_Tempo).subscribe(responseData => {
        this.tempoTrascorso=0;
    });
  }

  checkUserIsEnabled(idCorso, idCorsista) {
    let limiteConnessioneEffettuato=0;

    this.lockModuliUC=1;
    this.crudService.api_get_ElencoAulaCorsoTag(idCorso, idCorsista).subscribe(responseData => {
        if(responseData[0]) {
            console.log(responseData);

            this.isEnabledUser=true;
            this.elencoDataCorso=responseData;

            this.arrayLive = this.elencoDataCorso[0];

            //variabile sentinella, controlla se nello stesso giorno ho più eventi
            var isInLiveTMP=0;
            this.elencoDataCorso.forEach(element => {
                //se nello stesso giorno non ho più eventi allora valuto la condizione
                //altrimenti significa che ho già trovato l'evento del giorno con la live in corso
                if(isInLiveTMP==0) {
                    if(element['isToday']==1) {
                        this.isInLive=element['isLive'];
                        this.protocollo=element['protocollo'];
                        this.limita_moduloUC=element['limita_moduloUC'];
                        this.isInLiveLinkAula=element['link_aula'];
                        this.arrayLive=element;
                        if(this.elencoDataCorso.length<=1) {
                            if(element['isLive']==3)
                                this.lockModuliUC=0;
                        }
                        //controllo se l'evento è in live
                        if(element['isLive']==1)
                            isInLiveTMP=1;
                        }
                    }
            });

            //mi trovo l'evento live ed aggiorno i riferimenti
            var isOnlyDAD=1;
            var isEventDaySelected=0;
            this.elencoDataCorso.forEach(element => {
                    //console.log(element);

                    //mi controllo se l'ente ha scelto di far vedere SEMPRE la FAD in ogni giorno (oltre a quelli in calendario)
                    //viene tracciata però SOLO quella in calendario
                    //controllo se la FAD deve essere visualizzata anche nell'evento del giorno
                    if((element['limita_moduloUC']==1) && ((element['isLive']==1 || element['isLive']==2))) {
                        this.isFAD_Always=false;
                        isEventDaySelected=1;
                    }
                    else if((element['limita_moduloUC']==0) && (element['isFADAlways']==1) && isEventDaySelected==0) {
                            this.isFAD_Always=true;
                    }
                    else if(element['isFADAlways']==1 && isEventDaySelected==0)
                        this.isFAD_Always=true;


                    if(element['limita_moduloUC']==1)
                        isOnlyDAD=0;

                    if(this.lockModuliUC==1) {
                        if((element['limita_moduloUC']==1) && (!(element['isLive']==1))) {
                            this.lockModuliUC=1;
                        }
                    }
                    if(element['isLive']==5 || element['isLive']==2) {
                        this.reportForData=1;
                        this.limita_moduloUC=element['limita_moduloUC'];
                    }

                    if(element['isLive']==1) {
                        //console.log(element);
                        this.reportForData=1;


                        if(element['limita_moduloUC']==1) {
                            this.lockModuliUC=0;
                        }

                        this.limiteGiorno=0;
                        this.limiteGiornoAula=0;

                        //mi controllo se il limite giornaliero è stato raggiunto
                        if(element['limita_moduloUC']==1) {

                            //converto da minuti a secondi
                            if(parseFloat(element['limita_moduloUC_durata'])>0) {
                                this.limiteGiorno=(element['limita_moduloUC_durata']*60);
                                if(parseFloat(element['totaleConnessioneGiorno'])) {
                                    limiteConnessioneEffettuato=(parseFloat(element['totaleConnessioneGiorno']));
                                }

                                /*
                                if(parseFloat(element['totaleConnessioneGiorno'])>=parseFloat(element['limita_moduloUC_durata'])) {
                                    this.isLimiteRaggiunto=true;
                                }
                                */

                                if(limiteConnessioneEffettuato>=this.limiteGiorno) {
                                    this.isLimiteRaggiunto=true;
                                    this.lockModuliUC=1;
                                }

                                this.tempoRestanteLimite=(this.limiteGiorno-limiteConnessioneEffettuato);
                                this.labelTempoRestanteLimite=this.secondsToHms(this.tempoRestanteLimite);
                                this.tempoRestanteSessione=element['fineSeconds'];
                                this.labelTempoRestanteSessione=this.secondsToHms(this.tempoRestanteSessione);

                                this.timerHandle=setInterval(() => {
                                    this.aggiornaTempoSessione();
                                }, 1000);
                            }
                            else {
                                this.tempoRestanteLimite=element['fineSeconds'];
                                this.limiteGiorno=element['fineSeconds'];
                                this.isLimiteRaggiunto=false;
                                this.labelTempoRestanteLimite=this.secondsToHms(this.tempoRestanteLimite);
                                this.timerHandle=setInterval(() => {
                                    this.countTimerNoLimite();
                                }, 1000);
                            }

                        } else if((parseFloat(element['limita_aula_durata'])>0) && (element['limita_moduloUC']==0)) {
                            //converto da minuti a secondi
                            this.limiteGiornoAula=(element['limita_aula_durata']*60);
                            if(parseFloat(element['totaleConnessioneGiornoAula'])) {
                                limiteConnessioneEffettuato=(parseFloat(element['totaleConnessioneGiornoAula']));
                            }

                            this.tempoRestanteLimite=(this.limiteGiornoAula-limiteConnessioneEffettuato);
                            this.labelTempoRestanteLimite=this.secondsToHms(this.tempoRestanteLimite);

                            if(limiteConnessioneEffettuato>=this.limiteGiornoAula) {
                                this.isLimiteRaggiunto=true;
                            }
                            else {
                                this.timerHandle=setInterval(() => {
                                    this.countTimerAula();
                                }, 1000);
                            }
                        }

                        //element['limita_moduloUC']=this.lockModuliUC;
                        this.isInLive=element['isLive'];
                        this.limita_moduloUC=element['limita_moduloUC'];
                        this.isInLiveLinkAula=element['link_aula'];

                        /*
                        if(element['isToday']==1) {
                            console.log("aaaaaa");
                            this.arrayLive=element;
                            console.log("okkkk."+this.arrayLive);
                        }
                        */

                    }
            });
        }

        this.loadDetailsCorso(this.productID, isOnlyDAD);

    });

  }

  changeBriciola() {
      this.detallCorso.titolo=this.cntClick+" Provami...";
      this.cntClick++;
  }

  limiteRaggiunto(isRefresh) {
    Swal.fire({
        title:'Limite Raggiunto!',
        text: 'hai raggiunto il limite giornaliero per la visualizzazione dei video, consula la lista degli eventi (\"Elenco Date\").',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#6993FF',
        cancelButtonColor: '#dadada',
        confirmButtonText: 'Grazie',
        allowOutsideClick: false,
     }).then((result) => {
        if (result.isConfirmed) {
            if(isRefresh)
                location.reload();
        }
    });
  }

  videoTempoAggiornato() {
    Swal.fire({
        title:'Aggiornato!',
        text: 'il tempo di visualizzazione del video è stato aggiornato.',
        icon: 'success',
        showCancelButton: false,
        confirmButtonColor: '#6993FF',
        cancelButtonColor: '#dadada',
        confirmButtonText: 'Grazie',
        allowOutsideClick: false,
     }).then((result) => {
        if (result.isConfirmed) {

        }
    });
  }

  showDetailVideoConnessioni(arrayElement, idCorsoSvolgimento) {
    var elements="";
    var titleUC="";
    var paragrafo="";

    //console.log(arrayElement);
    arrayElement.forEach(element => {
        if(titleUC!=element['titoloUC']) {
            titleUC=element['titoloUC'];
            elements+="<h6 class='fw-bolder pt-3 text-uppercase' style='color:var(--mainColor)'>"+titleUC+"</h6>";
        }
        if(paragrafo!=element['paragrafo']) {
            paragrafo=element['paragrafo'];
            elements+="<h6 class='fw-bolder pt-2' style='color:var(--bs-black)'>"+paragrafo+"</h6>";
        }
        elements+='<div class="pb-1 row">\
                        <span class="col-lg-2 fw-bold p-0" style="text-align:right; color: #2dbbc4; font-size: 11pt;">'+element['durata']+'</span>\
                        <span class="col-lg-9 text-lowercase">'+element['titolo']+'</span>\
                    </div>';
    });

    if(arrayElement.length>0) {
        var txt='<div class="">'+elements+'</div>';
        document.getElementById('accordion_'+idCorsoSvolgimento).innerHTML = txt;
    }
    else {
        document.getElementById('accordion_'+idCorsoSvolgimento).innerHTML = "nessuna connessione effettuata";
    }

  }

  apriTestValutazione(indexSelected) {
    this.moduloUC_TestValutazioneSelected=this.moduloUC_TestValutazione[indexSelected];
    if(this.moduloUC_TestValutazione[indexSelected])
        this.showTestValutazione=true;
  }

  stampaTestValutazione(idQuizHeader) {
    let idCorsista=this.detailUser['id_corsista'];

    window.open(this.urlStampa+"report-test-valutazione.php?idCorsista="+idCorsista+"&idCorso="+this.idCorsoGlobal+"&idQuizHeader="+idQuizHeader+"&isTestValutazione=1");
  }

  chiudiTestValutazione() {
    this.showTestValutazione=false;
  }

  verificaTestValutazione(idQuizHeader) {
    var arrayQuiz=this.GetAllSelectRadioButtons();
    var arrayRisposte=new Array();

    let idCorsista=this.detailUser['id_corsista'];
    let idCorsoSvolgimento=this.arrayLive['id_corso_svolgimento'];
    let idCorso=this.detailQuiz['id_corso'];

    arrayQuiz.forEach((element) => {
        var domanda=element.name;
        var risposta=element.value;
        arrayRisposte.push({"id_corso":idCorso, "id_header":idQuizHeader, "id_corsista":idCorsista, "id_corso_svolgimento":idCorsoSvolgimento, "id_domanda":domanda, "id_risposta":risposta, "isTestValutazione": '1'});
    });

     //controllo se l'utente ha risposto a tutte le domande, in caso contrario visualizzo il msg
     if(arrayRisposte.length<this.moduloUC_TestValutazioneSelected['quizBody'].length) {
       this.quizNonCompleto();
        return;
    }

    this.crudService.api_verifica_quiz(arrayRisposte).subscribe(product => {
        let corrette=product['corrette'];
        let nonCorrette=product['non_corrette'];
        let numeroDomande=product['numero_domande'];
        let sogliaMinimaPercentuale=product['soglia_minima_percentuale'];
        let sogliaMinimaDomande=product['soglia_minima_domande'];
        let sogliaCorrettePercentuale=product['soglia_corrette_percentuale'];
        if(corrette>=sogliaMinimaDomande) {
            //test superato
            this.quizSuperato(corrette, numeroDomande);
        }
        else {
            //test non superato
            this.quizNonSuperato(corrette, nonCorrette);
        }

    });
  }

  downloadAttestato(idCorsista, idCorso, idCorsoSvolgimento) {
    //mi serve per la gestione della generazione dell'attestato
    var arrayDetail={'id_corso': idCorso, 'id_corsista': idCorsista, 'id_corso_svolgimento': idCorsoSvolgimento};

    //genero l'attestato
    this.crudService.api_GeneraAttestato(arrayDetail).subscribe(product => {
        //console.log(product);
        let nomeCorsista=product['nome_corsista'];
        let data_nascita=product['data_nascita'];
        let provincia_nascita=product['provincia_nascita'];
        let comune_nascita=product['comune_nascita'];
        let data_attestato=product['data_attestato'];
        let cf=product['cf'];
        let codiceAteco=product['codice_ateco'];
        let isPresent=product['isPresent'];
        let attestato=product['attestato'];
        let dataStart=product['dataStart'];
        if(isPresent.hasOwnProperty('id_certificato')) {
            window.open(environment.urlAttestati+isPresent.file);
        }
        else {
            if(attestato) {
                var detail='?id_corso='+idCorso+'&id_corso_svolgimento='+idCorsoSvolgimento+'&id_corsista='+idCorsista+'&nome_corsista='+nomeCorsista+'&data_nascita='+data_nascita+'&provincia_nascita='+provincia_nascita+'&comune_nascita='+comune_nascita+'&data_attestato='+data_attestato+'&dataStart='+dataStart+'&cf='+cf+'&protocollo='+this.protocollo+'&mansione='+this.mansione+'&codiceAteco='+codiceAteco;
                window.open(environment.urlAttestatiDaCreare+attestato+".php"+detail);
            }
            else {
                Swal.fire({
                    title: 'Errore!',
                    text: "non è stasto creato l\'attestato .pdf, non posso procedere alla generazione dell\'attestato",
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Risolvi',
                    cancelButtonText: 'Annulla'
                  }).then((result) => {
                    if (result.isConfirmed) {
                    }
                })
            }
        }
    });
  }

}
