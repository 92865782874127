
<style>
    .my-pagination /deep/ .ngx-pagination .pagination-next  a:after{
        margin-left: 0px;
    }
    .my-pagination /deep/ .ngx-pagination .pagination-next:after{
        margin-left: 0px;
    }
    .my-pagination /deep/ .ngx-pagination .pagination-previous  a:before{
        margin-right: 0px;
    }
    .my-pagination /deep/ .ngx-pagination .pagination-previous:before{
        margin-right: 0px;
    }
    .my-pagination /deep/ .ngx-pagination .current {
        background: var(--mainColor);
        text-align: center;
        display: inline-block;
        border-radius: 3px;
        position: relative;
        margin-left: 3px;
        margin-right: 3px;
        font-size: var(--fontSize);
        font-weight: 800
    }

    .my-pagination /deep/ .ngx-pagination li>a {
        background: #f3f0f0;
        color: #606060;
        text-align: center;
        display: inline-block;
        border-radius: 3px;
        position: relative;
        margin-left: 3px;
        margin-right: 3px;
        font-size: var(--fontSize);
        font-weight: 800
    }

    .my-pagination /deep/ .ngx-pagination .disabled {
        background: #f3f0f0;
        color: #606060;
        text-align: center;
        display: inline-block;
        border-radius: 3px;
        position: relative;
        margin-left: 3px;
        margin-right: 3px;
        font-size: var(--fontSize);
        font-weight: 800
    }
  </style>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <div class="page-title-content">
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><a href="/elenco-corsi">Elenco Corsi</a></li>
                    <li *ngIf="detailUser" ><a routerLink="/elenco-corsi/i-miei-corsi">I miei corsi</a></li>
                </ul>
                <h2>{{productID=="i-miei-corsi" ? 'Elenco Corsi - I miei corsi' : 'Elenco Corsi'}}</h2>
            </div>
        </div>
    </div>
</div>

<div class="courses-area ptb-100">
    <div class="container">
        <div class="row">
            <div [class]="findCorsista=='' ? 'col-lg-8 col-md-12' : 'col-lg-8 col-md-12'">
        <div class="odemy-grid-sorting row align-items-center">
            <div class="col-lg-12 col-md-6 result-count">
                <h5 class="fw-bolder" *ngIf="findCorsista==''">Ci sono <u><span *ngIf="elencoCorsi[0]" class="count">{{ elencoCorsi?.length }}</span></u> corsi per la tua formazione</h5>
                <h5 class="fw-bolder" *ngIf="findCorsista!=''">Elenco dei corsi acquistati per la tua formazione</h5>
            </div>
            <a href="/elenco-corsi">
                <h3 class="mt-2 fw-bolder" *ngIf="findCorsista!='' && elencoCorsi?.length<=0">Non hai acquistato nessun corso, inizia adesso la tua formazione, consulta i <u>nostri corsi</u>.</h3>
            </a>

            <div class="col-lg-6 col-md-6 ordering d-none">
                <div class="select-box">
                    <label>Sort By:</label>
                    <select [(ngModel)]="changeFilter" (change)="queryFilter()">
                        <option>Default</option>
                        <option>Popularity</option>
                        <option>Latest</option>
                        <option>Price: low to high</option>
                        <option>Price: high to low</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div  [class]="findCorsista=='' ? 'col-lg-6 col-md-6' : 'col-lg-6 col-md-6'" *ngFor="let corso of elencoCorsi | paginate: { itemsPerPage: nOffset, currentPage: nPagAttuale }">
                <div class="single-courses-box">
                    <div class="courses-image">
                        <img *ngIf="corso['corso'].isMiur==1" src="assets/img/miur.png" style="height: 50px; position: absolute; bottom: 20px; left: 25px;">
                        <a (click)="getNavigation('dettaglio-corso', corso['corso'].tag)" class="d-block image">
                            <img src="{{urlImage+corso['corso'].image}}" style="max-height:225px; width:100%; object-fit: cover; cursor: pointer;" alt="image">
                        </a>
                        <a routerLink="/" class="fav"></a>
                        <div *ngIf="(corso['corso'].costo_scontato<=0 && (corso['corso'].costo>0) && corso.haveBuy==0)" class="price shadow">€{{corso['corso'].costo}}</div>
                        <div *ngIf="(corso['corso'].costo_scontato>0  && corso.haveBuy==0)" class="price shadow">€{{corso['corso'].costo_scontato}}</div>
                    </div>
                    <div class="courses-content">
                        <h3><a routerLink="/dettaglio-corso/{{corso['corso'].tag}}">{{corso['corso'].titolo}}</a></h3>
                        <p style="overflow: hidden;
                                  text-overflow: ellipsis;
                                  display: -webkit-box;
                                  -webkit-line-clamp: 3; /* number of lines to show */
                                  -webkit-box-orient: vertical">{{corso['corso'].descrizione}}</p>
                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li *ngIf="corso['corso']['durata'].durata_numero>0" class="fw-bold"><i class='flaticon-time-left'></i>
                                {{corso['corso']['durata'].durata_numero}}{{corso['corso']['durata'].durata_label=='h' ? 'h' : ' '+corso['corso']['durata'].durata_label}}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="pagination-area text-center my-pagination">
                    <pagination-controls
                        autoHide="autoHide"
                        responsive="responsive"
                        previousLabel=""
                        nextLabel=""
                        (pageChange)="nPagAttuale = $event"></pagination-controls>
                  </div>
            </div>
            </div>
            </div>
            <div  [class]="findCorsista=='' ? 'col-lg-4  col-md-12' : 'd-none'">
                <aside class="widget-area">
                    <div class="widget widget_search">
                        <h3 class="widget-title">Cerca il tuo corso</h3>
                        <form class="search-form">
                            <label>
                                <span class="screen-reader-text">cosa stai cercando?</span>
                                <input id="queryFind" type="search" class="search-field" placeholder="cosa stai cercando?">
                            </label>
                            <button type="button" (click)="loadCorsi('5');" style="right:20px"><i class="bx bx-search-alt"></i></button>
                        </form>
                    </div>
                    <div class="widget widget_tag_cloud">
                        <h3 class="widget-title">Ricerca per categoria</h3>
                        <div class="tagcloud" *ngFor="let sottoCategoria of elencoSottoCategorie">
                            <a href="/elenco-corsi/{{sottoCategoria['tag']}}">{{sottoCategoria['nome']}} <span class="tag-link-count"> ({{sottoCategoria['cntCorsi']}})</span></a>
                        </div>
                    </div>
                    <div class="widget widget_recent_courses">
                        <h3 class="widget-title">Altri corsi interessanti</h3>
                        <article class="item" *ngFor="let rowCorsi of elencoPropostaCorsi">
                            <a routerLink="/dettaglio-corso/{{rowCorsi['corso']['tag']}}" class="thumb">
                                <img src="{{urlImage+rowCorsi['corso']['image']}}" class="fullimage" style="object-fit: cover">
                            </a>
                            <div class="info mt-0">
                                <span class="mb-0 d-none">€. 99.00</span>
                                <h4 class="title usmall">
                                    <a routerLink="/dettaglio-corso/{{rowCorsi['corso']['tag']}}">{{rowCorsi['corso']['titolo']}}</a>
                                </h4>
                                <span class="fst-normal text-lowercase text-black-50 mt-1" style="font-size: 11pt;">
                                    <i _ngcontent-gpa-c231="" class="flaticon-time-left" style="color: var(--mainColor)"></i>
                                    {{rowCorsi['corso']['durata']['durata_numero']+rowCorsi['corso']['durata']['durata_label']}}
                                </span>
                            </div>
                            <div class="clear"></div>
                        </article>
                    </div>
                </aside>
            </div>

            <div  [class]="findCorsista!='' ? 'col-lg-4  col-md-12' : 'd-none'">
                <aside class="widget-area">
                    <div class="widget widget_search">
                        <h3 class="widget-title">I tuoi dati</h3>
                        <div class="tagcloud w-100 p-3" style="background-color: #f5f5f5;">
                                <span class="text-uppercase fw-bolder"><b>{{detailCorsista.cognome+' '+detailCorsista.nome}}</b></span><br>
                                {{detailCorsista.indirizzo}}<br>
                                {{detailCorsista.cap ? detailCorsista.cap +" - " : ''+detailCorsista.citta}}
                                {{detailCorsista.provincia ? " ("+detailCorsista.provincia+")" : ''}}<br>
                                <div class="mt-2">
                                    telefono: <b>{{detailCorsista.telefono}}</b><br>
                                    e-mail: <b>{{detailCorsista.email}}</b>
                                </div>
                        </div>
                    </div>
                    <div class="widget widget_tag_cloud">
                        <h3 class="widget-title">Informazioni Documento</h3>
                        <div class="tagcloud w-100 p-3" style="background-color: #f5f5f5;">
                            <div class="mt-2">
                                C. F: <b>{{detailCorsista.cf ? detailCorsista.cf : '- vuoto -'}}</b><br>
                                Comune di Nascita: <b>{{detailCorsista.comune_nascita}}</b><br>
                                Data di Nascita: <b>{{detailCorsista.data_nascita | date:'dd/MM/Y'}}</b>
                            </div>
                        </div>
                    </div>
                    <div class="widget widget_tag_cloud">
                        <h3 class="widget-title">Informazioni Attestato</h3>
                        <div class="tagcloud w-100 p-3" style="background-color: #f5f5f5;">
                            <div class="mt-2">
                                Mansione: <b>{{detailCorsista.mansione ? detailCorsista.mansione : '- vuoto -'}}</b><br>
                                Codice Ateco: <b>{{detailCorsista.codice_ateco ? detailCorsista.codice_ateco : '- vuoto -'}}</b>
                            </div>
                        </div>
                    </div>
                    <div class="widget widget_tag_cloud">
                        <h3 class="widget-title">Login</h3>
                        <div class="tagcloud w-100 p-3" style="background-color: #f5f5f5;">
                            <div class="mt-2">
                                username: <b>{{detailCorsista.username}}</b><br>
                                password: <span style="color: var(--mainColor); cursor: pointer;" class="fw-bolder" (click)="cambiaPassword();">cambia la password</span>
                            </div>
                        </div>
                    </div>
                    <p>I dati non possono essere modificati in modo autonomo per la sicurezza sulla validità degli attestati, per eventuali modifiche contattare i nostri uffici.</p>
                    <p>Consulta la nostra area <a href="/contatti"><span style="color: var(--mainColor)" class="fw-bolder">Contatti</span></a></p>
                </aside>
            </div>

        </div>
    </div>
</div>
